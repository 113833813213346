import React from 'react'
import Banner from '../component/banners/main'
import Brands from '../component/brands'
import Reviews from '../component/reviews'
import WhyLove from '../component/whyLove'
import DoRight from '../component/doRigth'
import BookFlight from '../component/bookFlight'
import Compete from '../component/compete'
import CreateAcc from '../component/createAcc'
import PageHeaderText from '../component/pageHeaderText'
import { Helmet } from 'react-helmet'

const MainPage = () => {
  return (
    <div className='page page-main'>
      <Helmet>
        <link rel='canonical' href={window.location.href} />
      </Helmet>
      <div className='container'>
        <PageHeaderText text='Your Personal Travel Assistant' />
      </div>
      <Banner />
      <Brands />
      <Reviews />
      <WhyLove />
      <DoRight />
      <BookFlight />
      <Compete />
      <CreateAcc />
    </div>
  )
}

export default MainPage
