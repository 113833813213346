import React from 'react'
import './style.sass'
import Slider from './_slider'
import MobileSlider from './_mobileSlider'
import AppUrls from '../../../data/appsUrl'
import Apps from './_apps'
import useWindowSize from '../../../hook/useWindowSize'
import { logEvent } from '../../../analytics'
import { useInViewport } from '../../../hook/useInViewport'

const Banner = () => {
  const windowSize = useWindowSize()
  const appStoreRef = useInViewport(() => logEvent('aita_mp_store_main_storeButton_see', 'App Store'))
  const googlePlayRef = useInViewport(() => logEvent('aita_mp_store_main_storeButton_see', 'Google Play'))
  const galaxyStoreRef = useInViewport(() => logEvent('aita_mp_store_main_storeButton_see', 'Galaxy Store'))
  const refs = {
    'App Store': appStoreRef,
    'Google Play': googlePlayRef,
    'Galaxy Store': galaxyStoreRef
  }
  const handleClick = (name) => () => {
    logEvent('aita_mp_store_main_storeButton_tap', name)
  }
  return (
    <section className='banner'>
      <div className='container'>
        <div className='apps'>
          {AppUrls.map((app, index) => {
            return <Apps key={'app' + index} app={app} onClick={handleClick(app.name)} ref={refs[app.name]} />
          })}
        </div>
        <div className='subHead'>
          Designed for the modern frequent flier. Plan, book, track and manage your travels. Join our community of 6,000,000 users around the world.
        </div>
        {windowSize.desktop
          ? <Slider />
          : <MobileSlider />}

      </div>
    </section>
  )
}

export default Banner
