import React from 'react'
import './_apps.sass'

const Apps = React.forwardRef(({ app, onClick }, ref) => {
  return (
    <a
      href={app.url}
      target='blank'
      rel='opener'
      className='apps__el'
      onClick={onClick}
      ref={ref}
    >
      <img src={app.icon} alt='' className='img' />
      {app.name}
    </a>
  )
})

export default Apps
